<template>
  <section class="mt-5">
      <div class="row justify-content-center mt-5">
          <div class="col-lg-4 col-sm-6 col-md-6">
              <div class="features-item with-border">
                  <h3>Free Image Editor</h3>
                  <p>Transform your photos with advanced editing tools. Easy, powerful, and free.</p>
              </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-md-6">
              <div class="features-item with-border">
                  <h3>Free Image Background Removal</h3>
                  <p>Remove backgrounds effortlessly. Perfect, clean images in seconds.</p>
              </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-md-6">
              <div class="features-item with-border">
                  <h3>Free Image Compressor</h3>
                  <p>Reduce image size without losing quality. Fast, efficient, and free.</p>
              </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-md-6">
              <div class="features-item with-border">
                  <h3>Free Image Enlarger</h3>
                  <p>Enlarge images without compromising quality. Clear, detailed, and quick.</p>
              </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-md-6">
              <div class="features-item with-border">
                  <h3>Free Image Color Picker</h3>
                  <p>Identify and copy color codes instantly from your images.</p>
              </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-md-6">
              <div class="features-item with-border">
                  <h3>Free Image PNG Converter</h3>
                  <p>Effortlessly convert images to PNG. Maintain quality with fast, reliable conversion.</p>
              </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-md-6">
              <div class="features-item with-border">
                  <h3>Free GIF Maker</h3>
                  <p>Create engaging GIFs easily. Fun, fast, and creative.</p>
              </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-md-6">
              <div class="features-item with-border">
                  <h3>Free Wireframe Tool</h3>
                  <p>Design website and app layouts swiftly. User-friendly and precise.</p>
              </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-md-6">
              <div class="features-item with-border">
                  <h3>Free App Downloading OneLink</h3>
                  <p>One link for all app downloads. Simplify sharing and tracking.</p>
              </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-md-6">
              <div class="features-item with-border">
                  <h3>Free QR Code Generator</h3>
                  <p>Generate custom QR codes instantly. Versatile, quick, and effective.</p>
              </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-md-6">
              <div class="features-item with-border">
                  <h3>Free Bar Code Generator</h3>
                  <p>Create barcodes for your products easily. Reliable and user-friendly.</p>
              </div>
          </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped lang='scss'>
    p{
      font-size: 14px;
      color: #716c80;
    }
 .section-title{
    h2{
      line-height: 1.4;
      margin-bottom: 0;
      font-size: 36px;
      font-weight: 700;
      color: #262A37;
    }
    p{
      font-size: 21px;
      color: #716c80;
      margin-bottom: 15px;
    }
  }
  .image{
    img{
      width: 100%;
    }
  }
  section {
    .row{
      margin-top: 100px;
    }
  }
  .col-lg-4:has(.features-item), .col-sm-6:has(.features-item), .col-md-6:has(.features-item){
    margin-bottom: 25px;
  }
  .features-item{
    padding: 30px 19px;
    border: 1px solid #eee;
    transition: all 0.3s ease-in-out;
    margin-bottom: 0px;
    height: 100%;
    text-align: center;
    h3 {
        font-size: 20px;
        margin-bottom: 12px;
        color: #262a37;
        font-weight: 700;
    }
    p{
      margin-bottom: 0;
    }
  }
  .features-item:hover{
    box-shadow: 0 25px 75px rgba(25,34,64,.15);
  }
.intro-video-content .sub-title {
    display: block;
    margin-bottom: 10px;
    background: linear-gradient(44.44deg, #0053fe 7.79%, #3a7afe 94.18%);;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: .1em;
    font-weight: 600;
}
.intro-video-content h2{
    line-height: 1.4;
    color: #24365c;
    font-size: 48px;
    margin-bottom: 15px;
    font-weight: 700;
}
.intro-video-content li {
    color: #5f5f5f;
    font-size: 18px;
}
.intro-video-content .default-btn {
    margin-top: 10px;
    color: white;
    background: linear-gradient(44.44deg,#3a7afe .79%,#0053fe 94.18%);
    padding: 12px 35px;
    font-weight: 600;
    font-size: 14px;
    border-radius: 30px;
    text-decoration: none;
    color: white;
    display: inline-block;
    margin-bottom: 50px;
}
iframe{
  height: 400px;
  width: 100%;
  border-radius: 10px;
}
.single-features-item {
    background-color: white;
    transition: .5s;
    padding: 40px 30px 35px 110px;
    margin-bottom: 25px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
}
.single-features-item h3 {
    font-size: 20px;
    font-weight: 700;
}
.single-features-item:hover {
    box-shadow: 0 50px 100px -20px rgba(50,50,93,.1), 0 30px 60px -30px rgba(107,124,147,.15);
}
.single-features-item:hover::before {
    opacity: 1;
    visibility: visible;
}
.single-features-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: linear-gradient(44.44deg,#3a7afe .79%,#0053fe 94.18%);
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: .5s;
}
.single-features-item:hover a, .single-features-item:hover h3, .single-features-item:hover p {
    color: white;
}
.image{
    max-width: 100%;
}
@media (max-width: 769px) {
    .single-features-item{
        padding-left: 30px;
    }
    
}
@media (min-width: 1400px){
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1140px;
    }
}
</style>