<template>
    <footer class="footer-area footer-style-two pt-100" v-if="currentLanguage == 'en'">
        <div class="container">
        <div class="row justify-content-cente">
            <div class="single-footer-widget col-lg-3 widget_kiedo_contact_info">
            <h3>SplendApp Free Tools</h3>
            <p>
                Explore more tools and services at Splendapp. Enhance your digital journey with our suite of efficient and user-friendly online tools.
            </p>
            </div>
            <div class="single-footer-widget col-lg-3 widget_nav_menu">
            <h3>Free Tools</h3>
            <div class="menu-useful-links-container">
                <ul id="menu-useful-links" class="menu">
                <li
                    id="menu-item-28893"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-28893"
                >
                    <a href="https://splendapp.com/en/about-splendapp/"
                    >Image Editor</a
                    >
                </li>
                <li
                    id="menu-item-28894"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-28894"
                >
                    <a href="https://splendapp.com/en/why-splendapp/"
                    >Wireframe Tool</a
                    >
                </li>
                <li
                    id="menu-item-21644"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-21644"
                >
                    <a href="https://splendapp.com/en/features/"
                    >App Downloads OneLink</a
                    >
                </li>
                <li
                    id="menu-item-21353"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-21353"
                >
                    <a href="https://splendapp.com/en/faqs/">QR Code Generator</a>
                </li>
                <li
                    id="menu-item-21351"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-21351"
                >
                    <a href="https://splendapp.com/en/terms-of-use/"
                    >Barcode Generator</a
                    >
                </li>
                </ul>
            </div>
            </div>
            <div class="single-footer-widget col-lg-3 widget_nav_menu">
            <h3>Image Tools</h3>
            <div class="menu-contact-us-container">
                <ul id="menu-contact-us" class="menu">
                <li
                    id="menu-item-20639"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-20639"
                >
                    <a href="https://splendapp.com/en/home-3/"
                    >Image Background Removal</a
                    >
                </li>
                <li
                    id="menu-item-20640"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-20640"
                >
                    <a href="tel:+966115201560">Image Compressor</a>
                </li>
                <li
                    id="menu-item-20641"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-20641"
                >
                    <a href="https://splendapp.com/en/home-3/"
                    >Image Enlarger</a
                    >
                </li>
                <li
                    id="menu-item-20643"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-20643"
                >
                    <a href="tel:+201111220132">Image Color Picker</a>
                </li>
                <li
                    id="menu-item-20642"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-20642"
                >
                    <a href="mailto:info@splendapp.com">Image PNG Converter</a>
                </li>
                <li
                    id="menu-item-20642"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-20642"
                >
                    <a href="mailto:info@splendapp.com">GIF Maker</a>
                </li>
                </ul>
            </div>
            </div>
            <div class="single-footer-widget col-lg-3 widget_nav_menu">
            <h3>Social Media</h3>
            <div class="menu-useful-links-container">
                <ul id="menu-useful-links" class="menu">
                <li
                    id="menu-item-28893"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-28893"
                >
                    <a href="https://splendapp.com/en/about-splendapp/"
                    >Facebook</a
                    >
                </li>
                <li
                    id="menu-item-28894"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-28894"
                >
                    <a href="https://splendapp.com/en/why-splendapp/"
                    >Instagram</a
                    >
                </li>
                <li
                    id="menu-item-21644"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-21644"
                >
                    <a href="https://splendapp.com/en/features/"
                    >X</a
                    >
                </li>
                <li
                    id="menu-item-21353"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-21353"
                >
                    <a href="https://splendapp.com/en/faqs/">Linkedin</a>
                </li>
                <li
                    id="menu-item-21351"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-21351"
                >
                    <a href="https://splendapp.com/en/terms-of-use/"
                    >Youtube</a
                    >
                </li>
                </ul>
            </div>
            </div>
        </div>

        <div class="copyright-area">
            <p>Copyright @2022 <strong>SplendApp</strong>. All Rights Reserved</p>
        </div>
        </div>
    </footer>
     <footer class="footer-area footer-style-two pt-100 ar" v-else-if="currentLanguage == 'ar'">
        <div class="container">
        <div class="row justify-content-cente">
            <div class="single-footer-widget col-lg-3 widget_kiedo_contact_info">
            <h3>أدوات سبلينداب المجانية</h3>
            <p>
                استكشف المزيد من الأدوات والخدمات في سبلينداب. عزز رحلتك الرقمية مع مجموعة أدواتنا الفعّالة وسهلة الاستخدام
            </p>
            </div>
            <div class="single-footer-widget col-lg-3 widget_nav_menu">
            <h3>أدوات مجانية</h3>
            <div class="menu-useful-links-container">
                <ul id="menu-useful-links" class="menu">
                <li
                    id="menu-item-28893"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-28893"
                >
                    <a href="https://splendapp.com/en/about-splendapp/"
                    >محرر الصور</a
                    >
                </li>
                <li
                    id="menu-item-28894"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-28894"
                >
                    <a href="https://splendapp.com/en/why-splendapp/"
                    >أداة الرسم Wireframing</a
                    >
                </li>
                <li
                    id="menu-item-21644"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-21644"
                >
                    <a href="https://splendapp.com/en/features/"
                    >الرابط الواحد لتحميل التطبيق</a
                    >
                </li>
                <li
                    id="menu-item-21353"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-21353"
                >
                    <a href="https://splendapp.com/en/faqs/">مولد الرمز QR</a>
                </li>
                <li
                    id="menu-item-21351"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-21351"
                >
                    <a href="https://splendapp.com/en/terms-of-use/"
                    >مولد الباركود</a
                    >
                </li>
                </ul>
            </div>
            </div>
            <div class="single-footer-widget col-lg-3 widget_nav_menu">
            <h3>أدوات الصور المجانية</h3>
            <div class="menu-contact-us-container">
                <ul id="menu-contact-us" class="menu">
                <li
                    id="menu-item-20639"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-20639"
                >
                    <a href="https://splendapp.com/en/home-3/"
                    >إزالة خلفية الصورة</a
                    >
                </li>
                <li
                    id="menu-item-20640"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-20640"
                >
                    <a href="tel:+966115201560">ضاغط حجم الصور</a>
                </li>
                <li
                    id="menu-item-20641"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-20641"
                >
                    <a href="https://splendapp.com/en/home-3/"
                    >مكبر حجم الصور</a
                    >
                </li>
                <li
                    id="menu-item-20643"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-20643"
                >
                    <a href="tel:+201111220132">محدد لون الصور</a>
                </li>
                <li
                    id="menu-item-20642"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-20642"
                >
                    <a href="mailto:info@splendapp.com">محول الصور إلى PNG</a>
                </li>
                <li
                    id="menu-item-20642"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-20642"
                >
                    <a href="mailto:info@splendapp.com">صانع الـ GIF.</a>
                </li>
                </ul>
            </div>
            </div>
            <div class="single-footer-widget col-lg-3 widget_nav_menu">
            <h3>تواصل معنا</h3>
            <div class="menu-useful-links-container">
                <ul id="menu-useful-links" class="menu">
                <li
                    id="menu-item-28893"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-28893"
                >
                    <a href="https://splendapp.com/en/about-splendapp/"
                    >Facebook</a
                    >
                </li>
                <li
                    id="menu-item-28894"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-28894"
                >
                    <a href="https://splendapp.com/en/why-splendapp/"
                    >Instagram</a
                    >
                </li>
                <li
                    id="menu-item-21644"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-21644"
                >
                    <a href="https://splendapp.com/en/features/"
                    >X</a
                    >
                </li>
                <li
                    id="menu-item-21353"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-21353"
                >
                    <a href="https://splendapp.com/en/faqs/">Linkedin</a>
                </li>
                <li
                    id="menu-item-21351"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-21351"
                >
                    <a href="https://splendapp.com/en/terms-of-use/"
                    >Youtube</a
                    >
                </li>
                </ul>
            </div>
            </div>
        </div>

        <div class="copyright-area">
            <p>Copyright @2022 <strong>SplendApp</strong>. All Rights Reserved</p>
        </div>
        </div>
    </footer>
</template>

<script>
export default {
    data() {
    return {
      currentLanguage: this.$store.state.currentLanguage
    }
  },
  watch: {
        '$store.state.currentLanguage': function(newLanguage) {
            this.currentLanguage = newLanguage
        },
    },
};
</script>

<style>
footer {
  background-color: #f4f8fc;
  padding-top: 100px;
  padding-bottom: 0;
}
.footer-area .single-footer-widget {
  margin-bottom: 30px;
}
.single-footer-widget h3 {
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #e7e9ea;
  margin-bottom: 25px;
  font-size: 20px;
  color: #262a37;
  font-weight: 700;
}
.footer-area .single-footer-widget p {
  color: #666666;
  margin-top: -5px;
}
.single-footer-widget ul {
  margin-bottom: 0;
  list-style-type: none;
  padding-left: 17px;
}
.single-footer-widget ul li {
  margin-bottom: 12px;
  position: relative;
}
.footer-area .single-footer-widget ul li::before {
  content: "";
  height: 7px;
  width: 7px;
  left: -17px;
  top: 8px;
  position: absolute;
  background: #0053fe !important;
}
.single-footer-widget ul li a {
  color: #666666;
  display: inline-block;
  position: relative;
  text-decoration: none;
}
.copyright-area p {
  margin-bottom: 0;
}
footer.ar{
    direction: rtl;
}
footer.ar .single-footer-widget ul li::before{
    left: unset;
    right: -17px;
}
footer.ar .single-footer-widget ul{
    padding-right: 17px;
    padding-left: 40px;
}
</style>
