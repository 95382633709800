<template>
  <div  class="color-picker-container">
    <div class="card">
        <div  class="image-container" v-if="isImageLoaded">
            <img :src="imageSrc" alt="Uploaded Image" @click="handleImageClick" />
        </div>
    </div>
    <div class="card inputs">
        <input type="file" @change="handleImageUpload" accept="image/*" />
        <table class="pixel-table">
            <tbody>
                <tr v-for="(row, rowIndex) in displayedPixelData" :key="rowIndex">
                <td v-for="(pixel, columnIndex) in row" :key="columnIndex">
                    <div
                    class="pixel"
                    :class="{ 'selected-pixel': isSelectedPixel(rowIndex, columnIndex) }"
                    :style="{ backgroundColor: pixel.color }"
                    @click="mainSelectedPixel=pixel.color"
                    ></div>
                </td>
                </tr>
            </tbody>
        </table>
        <div v-if="selectedPixel" class="selected-color">
            <div class="color-display" :style="{ backgroundColor: mainSelectedPixel }"></div>
            <div class="color-values">
              <div class="rgb">
                <span class="rgb-value">RGB:  {{ mainSelectedPixel }}</span>
                <button class="copy-button" @click="copyRGBValue">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z"/></svg>
                </button>
              </div>
              <div class="hex">
                <span class="hex-value">HEX:  {{ convertToHex(mainSelectedPixel) }}</span>
                <button class="copy-button" @click="copyHexValue">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z"/></svg>
                </button>
              </div>
                
                
            </div>
        </div>
        <div v-else class="selected-color">
            <div class="color-display" style=" backgroundColor: white "></div>
            <div class="color-values">
              <div class="rgb">
                <span class="rgb-value">RGB:  rgb(255,255,255)</span>
                <button class="copy-button" @click="copyRGBValue">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z"/></svg>
                </button>
              </div>
              <div class="hex">
                <span class="hex-value">HEX:  #ffffff</span>
                <button class="copy-button" @click="copyHexValue">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z"/></svg>
                </button>
              </div>
                
                
            </div>
        </div>
    </div>
    
  </div>
</template>

<script>
import defaultImage from '../assets/defualt.jpg';
export default {
  data() {
    return {
      isImageLoaded: false,
      imageSrc: defaultImage,
      pixelData: [],
      selectedPixel: null,
      mainSelectedPixel: null,
    };
  },
  computed: {
    displayedPixelData() {
      if (!this.selectedPixel) {
        return this.pixelData.slice(0, 10).map(row => row.slice(0, 10));
      }

      const { rowIndex, columnIndex } = this.selectedPixel;
      const startRow = Math.max(rowIndex - 5, 0);
      const endRow = Math.min(startRow + 10, this.pixelData.length);
      const startColumn = Math.max(columnIndex - 5, 0);
      const endColumn = Math.min(startColumn + 10, this.pixelData[0].length);

      return this.pixelData.slice(startRow, endRow).map(row => row.slice(startColumn, endColumn));
    },
  },
  methods: {
    handleImageUpload(event) {
      const file = event.target.files[0];

      if (file) {
        this.isImageLoaded = false;

        const reader = new FileReader();
        reader.onload = () => {
          const image = new Image();
          image.src = reader.result;
          image.onload = () => {
            this.imageSrc = image.src;
            this.generatePixelData(image);
            this.isImageLoaded = true;
          };
        };
        reader.readAsDataURL(file);
      }
    },

    generatePixelData(image) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0);
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const pixelData = [];

      for (let row = 0; row < imageData.height; row++) {
        const pixelRow = [];

        for (let col = 0; col < imageData.width; col++) {
          const offset = (row * imageData.width + col) * 4;
          const color = `rgb(${imageData.data[offset]}, ${imageData.data[offset + 1]}, ${imageData.data[offset + 2]})`;
          pixelRow.push({ color });
        }

        pixelData.push(pixelRow);
      }

      this.pixelData = pixelData;
    },

    handleImageClick(event) {
      const { offsetX, offsetY } = event;
      const { naturalWidth, naturalHeight } = event.target;

      const columnRatio = naturalWidth / event.target.offsetWidth;
      const rowRatio = naturalHeight / event.target.offsetHeight;

      const columnIndex = Math.floor(offsetX * columnRatio);
      const rowIndex = Math.floor(offsetY * rowRatio);

      const pixel = this.pixelData[rowIndex][columnIndex];
      this.selectPixel(pixel, rowIndex, columnIndex);
    },

    selectPixel(pixel, rowIndex, columnIndex) {
      this.selectedPixel = {
        pixel,
        rowIndex,
        columnIndex,
      };
      this.mainSelectedPixel = this.selectedPixel.pixel.color;
    },

    isSelectedPixel(rowIndex, columnIndex) {
      if (!this.selectedPixel) {
        return false;
      }

      const { rowIndex: selectedRowIndex, columnIndex: selectedColumnIndex } = this.selectedPixel;

      return rowIndex === selectedRowIndex && columnIndex === selectedColumnIndex;
    },

    convertToHex(color) {
      //Here's the continuation of the script for the `convertToHex` method:

      const rgbValues = color.match(/\d+/g);

      if (rgbValues && rgbValues.length === 3) {
        const r = parseInt(rgbValues[0]);
        const g = parseInt(rgbValues[1]);
        const b = parseInt(rgbValues[2]);

        const hexR = r.toString(16).padStart(2, '0');
        const hexG = g.toString(16).padStart(2, '0');
        const hexB = b.toString(16).padStart(2, '0');

        return `#${hexR}${hexG}${hexB}`;
      }

      return '';
    },
    copyHexValue() {
      const hexValue = this.convertToHex(this.mainSelectedPixel);
      this.copyToClipboard(hexValue);
    },

    copyRGBValue() {
      this.copyToClipboard(this.mainSelectedPixel);
    },

    copyToClipboard(value) {
      const textarea = document.createElement('textarea');
      textarea.value = value;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
    },
  },
  mounted() {
    const image = new Image();
    image.src = this.imageSrc;
    image.onload = () => {
      this.generatePixelData(image);
      this.isImageLoaded = true;
    };
  },
};
</script>

<style scoped>
.image-container {
  position: relative;
  display: inline-block;
  max-height: 400px;
  overflow: hidden;
  cursor: crosshair;
  max-width: 100%;
  overflow: hidden;
}
.image-container img{
    max-width: 100%;
    max-height: 100%;
}

.pixel-table {
  border-collapse: collapse;
  margin-top: 40px;
}

.pixel {
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.selected-pixel {
  border: 2px solid #000;

}

.selected-color {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.color-display {
  display: inline-block;
  height: 65px;
  margin-left: 5px;
  border-radius: 10px;
  flex: 0 0 65px;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}

.color-values {
  margin-top: 5px;
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 0 75%;
}
.color-values > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 7px;
    font-size: 14px;
    font-weight: 500;
}
.color-values > div button{
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
}
.color-values > div button svg{
    width: 20px;
    height: 20px;
}

.rgb-value,
.hex-value {
  display: inline-block;
  margin-right: 10px;
}
.card{
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    flex: 0 0 48%;
    margin-right: 20px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)
}
.card:last-child{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.color-picker-container{
    background-color: #f8f8f8;
    height: calc(100vh - 100px);
    display: flex;
    padding: 50px;
    flex-wrap: nowrap;
}
.card.inputs{
    flex: 0 0 380px !important;
    padding: 30px 20px;
    box-sizing: border-box;
}
.color-picker-container{
    justify-content: center;
}
@media (max-width: 767px) {
  .color-picker-container{
    flex-direction: column;
  }
}
@media (max-width: 500px) {
  .color-picker-container{
    padding: 20px;
  }
  .card{
    margin-right: 0;
    margin-top: 10px;
  }
  .card:first-of-type{
    max-height: 200px;
  }
  .image-container{
    max-height: 100%;
  }
}
@media (max-width: 450px) {
  .color-picker-container{
    padding: 20px;
  }
}

</style>