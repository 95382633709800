import Vue from 'vue'
import Router from 'vue-router'
import ImageCompress from '../pages/ImageCompress.vue'
import BackgroundRemoval from '../pages/BackgroundRemoval.vue'
import ColorPicker from '../pages/ColorPicker.vue'
import GifConverter from '../pages/GifConverter.vue'
import ImageEditor from '../pages/ImageEditor.vue'
import FormatConverter from '../pages/FormatConverter.vue'
import store from '../store'
import Home from '../pages/Home.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,

  routes : [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/%D8%B6%D8%A7%D8%BA%D8%B7-%D8%AD%D8%AC%D9%85-%D8%A7%D9%84%D8%B5%D9%88%D8%B1-%D8%A7%D9%84%D9%85%D8%AC%D8%A7%D9%86%D9%8A-%D8%A3%D9%88%D9%86%D9%84%D8%A7%D9%8A%D9%86-%D9%85%D9%86-%D8%B3%D8%A8%D9%84%D9%8A%D9%86%D8%AF%D8%A7%D8%A8',
    name: 'image-compress-ar',
    component: ImageCompress,
    beforeEnter: (to, from, next) => {
        // Set the current language to 'en' in the store
        store.commit('setCurrentLanguage', 'ar');
        next();
    },
  },
  {
    path: '/free-image-compressor-from-splendapp',
    name: 'image-compress-en',
    component: ImageCompress,
    beforeEnter: (to, from, next) => {
        // Set the current language to 'en' in the store
        store.commit('setCurrentLanguage', 'en');
        next();
    },
  },
  {
    path: '/%D8%A3%D8%AF%D8%A7%D8%A9-%D8%A5%D8%B2%D8%A7%D9%84%D8%A9-%D8%AE%D9%84%D9%81%D9%8A%D8%A9-%D8%A7%D9%84%D8%B5%D9%88%D8%B1%D8%A9-%D8%A3%D9%88%D9%86%D9%84%D8%A7%D9%8A%D9%86-%D9%85%D8%AC%D8%A7%D9%86%D8%A7%D9%8B-%D9%85%D9%86-%D8%B3%D8%A8%D9%84%D9%8A%D9%86%D8%AF%D8%A7%D8%A8',
    name: 'background-removal-ar',
    component: BackgroundRemoval,
    beforeEnter: (to, from, next) => {
        // Set the current language to 'en' in the store
        store.commit('setCurrentLanguage', 'ar');
        next();
    },
  },
  {
    path: '/free-image-editor-from-splendapp',
    name: 'background-removal-en',
    component: BackgroundRemoval,
    beforeEnter: (to, from, next) => {
        // Set the current language to 'en' in the store
        store.commit('setCurrentLanguage', 'en');
        next();
    },
  },
  {
    path: '/%D9%85%D8%AD%D8%AF%D8%AF-%D9%84%D9%88%D9%86-%D8%A7%D9%84%D8%B5%D9%88%D8%B1-%D8%A7%D9%84%D9%85%D8%AC%D8%A7%D9%86%D9%8A-%D8%A3%D9%88%D9%86%D9%84%D8%A7%D9%8A%D9%86-%D9%85%D9%86-%D8%B3%D8%A8%D9%84%D9%8A%D9%86%D8%AF%D8%A7%D8%A8',
    name: 'color-picker-ar',
    component: ColorPicker,
    beforeEnter: (to, from, next) => {
        // Set the current language to 'en' in the store
        store.commit('setCurrentLanguage', 'ar');
        next();
    },
  },
  {
    path: '/free-image-color-picker-from-splendapp',
    name: 'color-picker-en',
    component: ColorPicker,
    beforeEnter: (to, from, next) => {
        // Set the current language to 'en' in the store
        store.commit('setCurrentLanguage', 'en');
        next();
    },
  },
  {
    path: '/%D8%B5%D8%A7%D9%86%D8%B9-%D8%A7%D9%84%D9%80%20GIF-%D8%A7%D9%84%D9%85%D8%AC%D8%A7%D9%86%D9%8A-%D8%A3%D9%88%D9%86%D9%84%D8%A7%D9%8A%D9%86-%D9%85%D9%86-%D8%B3%D8%A8%D9%84%D9%8A%D9%86%D8%AF%D8%A7%D8%A8',
    name: 'gif-converter-ar',
    component: GifConverter,
    beforeEnter: (to, from, next) => {
        // Set the current language to 'en' in the store
        store.commit('setCurrentLanguage', 'ar');
        next();
    },
  },
  {
    path: '/free-gif-maker-from-splendapp',
    name: 'gif-converter-en',
    component: GifConverter,
    beforeEnter: (to, from, next) => {
        // Set the current language to 'en' in the store
        store.commit('setCurrentLanguage', 'en');
        next();
    },
  },

  {
    path: '/%D9%85%D8%AD%D8%B1%D8%B1-%D9%88%D9%85%D8%B5%D9%85%D9%85-%D8%A7%D9%84%D8%B5%D9%88%D8%B1-%D8%A7%D9%84%D9%85%D8%AC%D8%A7%D9%86%D9%8A-%D9%85%D9%86-%D8%B3%D8%A8%D9%84%D9%8A%D9%86%D8%AF%D8%A7%D8%A8',
    name: 'image-editor-ar',
    component: ImageEditor,
    beforeEnter: (to, from, next) => {
        // Set the current language to 'en' in the store
        store.commit('setCurrentLanguage', 'ar');
        next();
    },
  },
  {
    path: '/free-image-editor-from-splendapp',
    name: 'image-editor-en',
    component: ImageEditor,
    beforeEnter: (to, from, next) => {
        // Set the current language to 'en' in the store
        store.commit('setCurrentLanguage', 'en');
        next();
    },
  },
  
    {
      path: '/%D9%85%D8%AD%D9%88%D9%84_%D8%AA%D9%86%D8%B3%D9%8A%D9%82%D8%A7%D8%AA_%D9%88%D8%B5%D9%8A%D8%BA_%D8%A7%D9%84%D8%B5%D9%88%D8%B1_%D9%85%D9%86_%D8%B3%D8%A8%D9%84%D9%8A%D9%86%D8%AF%D8%A7%D8%A8',
      name: 'format-converter-ar',
      component: FormatConverter,
      beforeEnter: (to, from, next) => {
          // Set the current language to 'en' in the store
          store.commit('setCurrentLanguage', 'ar');
          next();
      },
    },
    {
      path: '/FreeImageFormatterfromSplendApp',
      name: 'format-converter-en',
      component: FormatConverter,
      beforeEnter: (to, from, next) => {
          // Set the current language to 'en' in the store
          store.commit('setCurrentLanguage', 'en');
          next();
      },
    },
    {
      path: '*',
      redirect: '/'
    }



]

})

export default router
