<template>
  <!--<router-link style="display: block;width: fit-content;padding: 20px 50px;background-color: black;margin: auto;color: white" :to="{name : 'image-compress-ar'}">Image Compresser</router-link>
  <router-link style="display: block;width: fit-content;padding: 20px 50px;background-color: black;margin: auto;color: white" :to="{name : 'background-removal-en'}">Background Removal</router-link>-->
  <h1></h1>
  

</template>

<script>
export default {

}
</script>

<style>

</style>