<template>
  <div class="background-removal">
    <div class="resizer-image">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"/></svg>
      <span>Drop Image Here</span>
    </div>
    <label for="image-input">choose file</label>
    <input style="display: none" id="image-input" type="file" @change="handleFileChange" accept="image/*" />
    <div v-if="isLoading">
      <p>Processing...</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl: null,
      isLoading: false,
      progress: 0,
      resultUrl: null
    };
  },
  methods: {
    async handleFileChange(event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = async () => {
        const image_src = reader.result;

        try {
          this.isLoading = true;
          this.progress = 0;

          // Dynamically import the library
          const { default: imglyRemoveBackground } = await import(
            "@imgly/background-removal"
          );

          imglyRemoveBackground(image_src, {
            onProgress: (p) => {
              this.progress = p * 100;
            }
          }).then((blob) => {
            const url = URL.createObjectURL(blob);

            this.imageUrl = url;
            this.resultUrl = url;
            this.isLoading = false;
            if(this.resultUrl){
                console.log(this.isLoading);
                this.downloadResult();
            }
          });
        } catch (error) {
          console.error("Failed to load the background-removal library:", error);
        }
      };

      reader.readAsDataURL(file);
    },
    downloadResult() {
      const link = document.createElement("a");
      link.href = this.resultUrl;
      link.download = "background_removed.png";
      link.click();
    }
  }
};
</script>
<style scoped>
img {
  max-width: 500px;
}
.background-removal{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4796ec;
    height: 280px;
    flex-direction: column;
}
.resizer-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2rem;
}
.resizer-image svg {
  width: 50px;
  height: 50px;
  fill: white;
}
label{
  cursor: pointer;
  padding: .5rem 1rem;
  background-color: #fff;
  border-radius: 10px;
  color: black;
  font-weight: bold;
  margin-top: 10px;
}
</style>