<template>
<div class="container">
<div class="row">
  <div class="card">
    <div class="card-header">
      <input style="display:none" type="file" accept="image/*" multiple @change="handleFileChange"  id="image-input" />
      <label class="btn-primary upload" for="image-input">Upload Images</label>
    </div>
    <div class="card-body">
      <div class="right-side">
        <div class="form-group" v-if="selectedFiles.length > 0">
          <label for="" class="col-form-label ">Delay</label>
          <input type="text" v-model="delay" @change="update()" />
        </div>
        <div class="form-group" style="display:flex;justify-content: space-between" v-if="selectedFiles.length > 0">
          <div class="item">
            <label for="" class="col-form-label ">Width</label>
            <input type="text" id="width" v-model="imageWidth" @change="createGif" />
          </div>
          <div class="item">
            <label for="" class="col-form-label ">Height</label>
            <input type="text" id="height" v-model="imageHeight" @change="createGif" />
          </div>
        </div>
        <div class="form-group" v-if="selectedFiles.length > 0">
          <label for="" class="col-form-label ">Title</label>
          <input type="text" v-model="title" @change="update()" />
        </div>
        <div class="down-buttons">
          <button v-if="downloadUrl" @click="reset()" class="btn-primary">Reset</button>
          <a v-if="downloadUrl" :href="downloadUrl" id="download-link">
            <button class="btn-primary">Download GIF</button>
          </a>
        </div>
        <div class="sort-images" v-if="selectedFiles.length > 0" style="text-align: left">
          <label for="" style="margin-bottom : 10px;display: block">Sort Images</label>
          <div class="images" style="display: flex;">
            <div v-for="(item, index) in selectedFiles" :key="index" style="margin-right: 10px;border: 1px solid #bbbbbb6e;border-radius: 7px;position: relative">
              <img :src="item.url" style="border-radius: 7px;max-width: 100px;height: 120px;" />
              <button @click="deleteImage(index)" style="border: none;background-color: red;display: inline-flex;justify-content: center;align-items: center;color: white;border-radius: 3px;padding: 5px 8px;font-size: 9px;position: absolute;top: 2px;right: 3px;"><svg style="fill: white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg></button>
            </div>
          </div>
          
        </div>
      </div>
      <div class="left-side" v-if="downloadUrl">
        <div class="mobile-content " >
          <img class="img-fluid" v-if="downloadUrl" :src="downloadUrl" style="max-height: 100%; border-radius: 18px;"></div>
        </div>
      </div>
    </div>
</div>
</div>
  
</template>

<script>
import gifshot from 'gifshot'

export default {
  data() {
    return {
      selectedFiles: [],
      downloadUrl: '',
      delay: 1000,
      buttonWord: 'Create GIF',
      imageWidth: null,
      imageHeight: null,
      title: 'gif'
    }
  },
  computed: {
    realDelay() {
      return this.delay / 1000;
    },
  },
  methods: {
    reset() {
      this.selectedFiles = []
      this.downloadUrl = ''
      this.delay = 1000
      this.buttonWord = 'Create GIF'
      this.imageWidth = 400
      this.imageHeight = 300
    },
    update() {
      this.createGif()
    },
    handleFileChange(event) {
      const files = event.target.files;
      if (files) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const url = window.URL.createObjectURL(file);
          this.selectedFiles.push({ file, url });
        }
        // Set default width and height with the first image uploaded
    const firstImage = this.selectedFiles[0];
    const image = new Image();
    image.src = firstImage.url;
    image.addEventListener('load', () => {
      this.imageWidth = image.width;
      this.imageHeight = image.height;
      this.createGif();
    });
      }
      this.createGif();
    },
    deleteImage(index) {
      this.selectedFiles.splice(index, 1);
      this.createGif();
    },
    createGif() {
  const imageUrls = this.selectedFiles.map((file) => file.url)

  gifshot.createGIF(
    {
      images: imageUrls,
      interval: this.realDelay,
      gifWidth: this.imageWidth,
      gifHeight: this.imageHeight,
    },
    (obj) => {
      if (!obj.error) {
        const base64Gif = obj.image
        const blob = this.base64ToBlob(base64Gif, 'image/gif')
        if (blob) {
          const url = URL.createObjectURL(blob)

          // Set custom name for the download
          const downloadName =`${this.title}.gif`;

          this.downloadUrl = url;
          this.$nextTick(() => {
            const downloadLink = document.getElementById('download-link');
            downloadLink.download = downloadName;
          });
        } else {
          console.error('Failed to convert base64 to blob.')
        }
      } else {
        console.error(obj.error)
      }
    }
  )
},
    base64ToBlob(base64, type) {
      const base64WithoutPrefix = base64.replace(/^data:image\/[a-z]+;base64,/, '');
      const binaryString = window.atob(base64WithoutPrefix);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      return new Blob([bytes], { type });
    }
  },
  watch: {
    selectedFiles() {
      this.createGif();
    },
  },
}
</script>
<style scoped>
.container{
    background-color: #f8f8f8;
    padding: 10px;
}
.card{
    background-color: #fff;
    border: 0 solid transparent;
    border-radius: 0.375rem;
    box-shadow: 0 0 13px 0 rgba(82,63,105,.05);
    height: calc(100% - 30px);
    margin-bottom: 1.875rem;
    position: relative;
    transition: all .5s ease-in-out;
    flex: 1!important;
    margin: 0 15px;
    min-height: 307px;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    max-width: 100%;
    overflow-x: hidden;
}
.mobile{
    background-color: #fff;
    border-radius: 22px;
    height: 500px;
    padding: 12px 7px;
    position: relative;
    transition: .3s;
    width: 250px;
}
.mobile:after, .mobile:before {
    background-color: #dbdada;
    border-radius: 10px;
    content: "";
    position: absolute;
    right: -2px;
    width: 2px;
}
.mobile:before {
    height: 50px;
    top: 180px;
}
.mobile:after {
    height: 80px;
    top: 80px;
}
.other-container {
    border-radius: 18px;
    height: 100%;
    position: relative;
    width: 100%;
}
.mobile-header {
    display: flex;
    justify-content: center;
}
.mobile-content{
    border: 1px solid #eee;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    /* height: calc(100% - 10px); */
    border-radius: 15px;
    /* margin: auto; */
    margin-top: 70px;
    margin-bottom: auto;
}
.img-fluid {
    height: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 18px ;
}
.other-container:before {
    background-color: #dbdada;
    border-radius: 50px;
    content: "";
    height: 1px;
    left: 50%;
    opacity: .5;
    position: absolute;
    top: -5px;
    transform: translateX(-50%);
    width: 90px;
}
.camera {
    border: 1px solid #d9d7d7;
    border-radius: 50%;
    bottom: -15px;
    height: 10px;
    position: relative;
    width: 10px;
}
.card-header {
    align-items: center;
    background: transparent;
    border-color: #d7dae3;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    position: relative;
}
.btn-primary {
    background-color: #3a7afe;
    border-color: #3a7afe;
    color: #fff;
    display: inline-block;
    padding: 0.3rem 1.25rem;
    border-radius: 0.375rem;
    font-size: .813rem;
}
.upload {
    padding: 10px;
}
.card-body {
    padding: 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}
.form-group {
    margin-bottom: 1rem;
    text-align: left;
}
.col-form-label {
    font-size: inherit;
    line-height: 1.5;
    margin-bottom: 0;
    padding-bottom: calc(0.375rem + 1px);
    padding-top: calc(0.375rem + 1px);
    max-width: 25%;
}
input{
  background-clip: padding-box;
    background-color: #fff;
    border-radius: 0.375rem;
    color: #495057;
    display: block;
    font-size: .875rem;
    font-weight: 400;
    height: calc(1.5em + 0.75rem + 2px);
    line-height: 1.5;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: calc(100% - 15px);
  background: #fff;
    border: 1px solid #d7dae3;
    color: #181c32;
    height: 40px;
    max-width: 100%;
    margin-top: 10px;
    padding-left: 15px;
}
.item{
  flex: 0 0 48%;
}
.left-side, .right-side{
  width: 48%;
  max-width: 48%;
}
.container{
  max-width: 1070px;
  margin: auto;
}
.down-buttons{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>