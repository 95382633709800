<template>
   <section class="mt-5">
      <div class="row justify-content-center mt-5">
          <div class="col-lg-4 col-sm-6 col-md-6">
              <div class="features-item with-border">
                  <h3>محرر الصور المجاني</h3>
                  <p>حول وطور صورك بأدوات تحرير متقدمة. سهل، قوي ومجاني</p>
              </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-md-6">
              <div class="features-item with-border">
                  <h3>إزالة خلفية الصورة مجاناً</h3>
                  <p>أزل الخلفيات بسهولة. صور بجودة عالية وكاملة في ثوان.</p>
              </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-md-6">
              <div class="features-item with-border">
                  <h3>ضاغط حجم الصور المجاني</h3>
                  <p>قلل حجم الصور دون فقدان الجودة. سريع وفعال.</p>
              </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-md-6">
              <div class="features-item with-border">
                  <h3>مكبر حجم الصور المجاني</h3>
                  <p>كبر أبعاد صورك دون التأثير على الجودة. واضح ودقيق.</p>
              </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-md-6">
              <div class="features-item with-border">
                  <h3>محدد لون الصور المجاني</h3>
                  <p>تعرف على الألوان وانسخ الأكواد من صورك فورًا.</p>
              </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-md-6">
              <div class="features-item with-border">
                  <h3>محول الصور إلى PNG المجاني</h3>
                  <p>حوّل صورك إلى تنسيق PNG بسهولة. حافظ على الجودة مع التحويل السريع والموثوق.</p>
              </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-md-6">
              <div class="features-item with-border">
                  <h3>صانع الـ GIF المجاني</h3>
                  <p>أنشئ GIFs جذابة بسهولة. ممتع، سريع وإبداعي.</p>
              </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-md-6">
              <div class="features-item with-border">
                  <h3>أداة الرسم Wireframing المجانية</h3>
                  <p>صمم تخطيطات مواقع وتطبيقات بسرعة. سهل الاستخدام ودقيق.</p>
              </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-md-6">
              <div class="features-item with-border">
                  <h3>رابط تحميل التطبيق المجاني</h3>
                  <p>رابط واحد لجميع روابط تحميل تطبيقك. يسهل المشاركة والتتبع.</p>
              </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-md-6">
              <div class="features-item with-border">
                  <h3>مولد الرمز QR </h3>
                  <p>أنشئ رموز QR مخصصة فورًا. متعدد الاستخدامات، سريع وفعال.</p>
              </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-md-6">
              <div class="features-item with-border">
                  <h3>مولد الباركود المجاني</h3>
                  <p>أنشئ باركود لمنتجاتك بسهولة. موثوق وسهل الاستخدام.</p>
              </div>
          </div>
          
        </div>
    </section>
</template>

<script>
export default {

}
</script>
<style scoped lang='scss'>
    p{
      font-size: 14px;
      color: #716c80;
    }
 .section-title{
    h2{
      line-height: 1.4;
      margin-bottom: 0;
      font-size: 36px;
      font-weight: 700;
      color: #262A37;
    }
    p{
      font-size: 21px;
      color: #716c80;
      margin-bottom: 15px;
    }
  }
  .image{
    img{
      width: 100%;
    }
  }
  section {
    .row{
      margin-top: 100px;
    }
  }
  .col-lg-4:has(.features-item), .col-sm-6:has(.features-item), .col-md-6:has(.features-item){
    margin-bottom: 25px;
  }
  .features-item{
    padding: 30px 19px;
    border: 1px solid #eee;
    transition: all 0.3s ease-in-out;
    margin-bottom: 0px;
    height: 100%;
    text-align: center;
    h3 {
        font-size: 20px;
        margin-bottom: 12px;
        color: #262a37;
        font-weight: 700;
    }
    p{
      margin-bottom: 0;
    }
  }
  .features-item:hover{
    box-shadow: 0 25px 75px rgba(25,34,64,.15);
  }
.intro-video-content .sub-title {
    display: block;
    margin-bottom: 10px;
    background: linear-gradient(44.44deg, #0053fe 7.79%, #3a7afe 94.18%);;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: .1em;
    font-weight: 600;
}
.intro-video-content h2{
    line-height: 1.4;
    color: #24365c;
    font-size: 48px;
    margin-bottom: 15px;
    font-weight: 700;
}
.intro-video-content li {
    color: #5f5f5f;
    font-size: 18px;
}
.intro-video-content .default-btn {
    margin-top: 10px;
    color: white;
    background: linear-gradient(44.44deg,#3a7afe .79%,#0053fe 94.18%);
    padding: 12px 35px;
    font-weight: 600;
    font-size: 14px;
    border-radius: 30px;
    text-decoration: none;
    color: white;
    display: inline-block;
    margin-bottom: 50px;
}
iframe{
  height: 400px;
  width: 100%;
  border-radius: 10px;
}
.single-features-item {
    background-color: white;
    transition: .5s;
    padding: 40px 30px 35px 110px;
    margin-bottom: 25px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
}
.single-features-item h3 {
    font-size: 20px;
    font-weight: 700;
}
.single-features-item:hover {
    box-shadow: 0 50px 100px -20px rgba(50,50,93,.1), 0 30px 60px -30px rgba(107,124,147,.15);
}
.single-features-item:hover::before {
    opacity: 1;
    visibility: visible;
}
.single-features-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: linear-gradient(44.44deg,#3a7afe .79%,#0053fe 94.18%);
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: .5s;
}
.single-features-item:hover a, .single-features-item:hover h3, .single-features-item:hover p {
    color: white;
}
.image{
    max-width: 100%;
}
@media (max-width: 769px) {
    .single-features-item{
        padding-left: 30px;
    }
    
}
@media (min-width: 1400px){
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1140px;
    }
}
</style>