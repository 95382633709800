<template>
<div class="container mt-5" v-if="currentLanguage == 'en'">
    <section class="mb-5">
            <div class="section-title text-center mb-5">
              <h2>Free Image Format Converter from SplendApp</h2>
              <p>Easily Convert Your Images to Any Format between JPG, JPEG, PNG, WebP, SVG, and more.</p>
            </div>
           <div class="tool">
                <formatConverter-com></formatConverter-com>
            </div>
            <div class="text-center mt-3">
                <a href="https://splendapp.com/en/" target="_blank">
                    <img src="@/assets/EN/3.gif" alt="banner" class="image">
                </a>
                
            </div>
    </section>
    <tools-comen></tools-comen>
    <section>
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="intro-video-content">
                <h2>How It Works</h2>
                <ul>
                    <li>Select Your Image</li>
                    <li>Choose the Format</li>
                    <li>Convert and Download</li>
                </ul>
                <a href="#" class="default-btn">Use It Free</a>
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="intro-video-box">
              <iframe src="https://www.youtube.com/embed/c7rB-xLy9Iw?si=Z7w3oToy2foIbEMq" title="YouTube video player" frameborder="0"></iframe>
            </div>
        </div>
      </div>
    </section>
    <section>
      <div class="row justify-content-center">
          <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
              <div class="single-features-item">
                      <h3>Select Your Image</h3>
                      <p>Upload the image you wish to convert from your device.</p>
                  </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                  <div class="single-features-item">
                      <h3>Choose the Format</h3>
                      <p>Select your desired output format from our extensive list.</p>
                  </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                  <div class="single-features-item">
                      <h3>Convert and Download</h3>
                      <p>Click convert, then download your newly formatted image instantly.</p>
                  </div>
              </div>
          </div>
    </section>
    <div class="text-center mt-3 mb-4">
        <a href="https://splendapp.com/en/" target="_blank">
            <img src="@/assets/EN/google AD banner 1 -EN.png" alt="banner" class="image">
        </a>
    </div>
  </div>
  <div class="container mt-5"  style="direction: rtl;" v-else-if="currentLanguage == 'ar'">
    <section class="mb-5"> 
            <div class="section-title text-center mb-5">
              <h2>محول تنسيقات وصيغ الصور من سبلينداب</h2>
              <p>قم بتحويل تنسيقات صورك فورًا مع محول تنسيقات٬ دعم كامل لتنسيقات JPG، JPEG، PNG، WebP، SVG والمزيد</p>
            </div>
            <div class="tool">
                <formatConverter-com></formatConverter-com>
            </div>
            <div class="text-center mt-3">
                <a href="https://splendapp.com/ar/" target="_blank">
                    <img src="@/assets/AR/Gif/1.gif" alt="banner" class="image">
                </a>
            </div>
    </section>
    <tools-comar></tools-comar>
    <section>
      <div class="row align-items-center" style="direction: rtl">
        <div class="col-lg-6 col-md-12">
            <div class="intro-video-content">
                <h2>كيف تعمل</h2>
                <ul>
                    <li>قم بتحميل صورتك</li>
                    <li>اختر التنسيق المطلوب</li>
                    <li>قم بالتحويل والتنزيل</li>
                </ul>
                <a href="" class="default-btn">أستخدمها مجانا</a>
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="intro-video-box">
              <iframe src="https://www.youtube.com/embed/c7rB-xLy9Iw?si=Z7w3oToy2foIbEMq" title="YouTube video player" frameborder="0"></iframe>
            </div>
        </div>
      </div>
    </section>
    <section>
      <div class="row justify-content-center" style="direction: rtl">
          <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
              <div class="single-features-item">
                      <h3>قم بتحميل صورتك</h3>
                      <p>ابدأ بتحميل الصورة التي ترغب في تحويلها.</p>
                  </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                  <div class="single-features-item">
                      <h3>اختر التنسيق المطلوب</h3>
                      <p>حدد التنسيق الجديد لصورتك من بين مجموعة واسعة من التنسيقات.</p>
                  </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
                  <div class="single-features-item">
                      <h3>قم بالتحويل والتنزيل</h3>
                      <p>اضغط على تحويل، ثم قم بتنزيل صورتك المحولة فوراً.</p>
                  </div>
              </div>
          </div>
    </section>
    <div class="text-center mt-3 mb-4">
        <a href="https://splendapp.com/ar/" target="_blank">
            <img src="@/assets/AR/Gif/2.gif" alt="banner" class="image">
        </a>
    </div>
  </div>
</template>

<script>
import FormatConverterCom from '@/components/FormatConverterCom.vue';
import ToolsComen from '@/components/ToolsComen.vue';
import ToolsComar from '@/components/ToolsComar.vue';
export default {
  name: 'YourComponent',
  data() {
    return {
      currentLanguage: this.$store.state.currentLanguage,
      title: '',
        description: ''
    }
  },
    components: {
        FormatConverterCom,
        ToolsComen,
        ToolsComar,
    },
    mounted() {
        if(this.currentLanguage == 'en'){
            this.title = 'Free Image Converter | Image Format Converter |SplendApp';
            this.description = "Save money, time and effort in crafting unique and effective images with Free Image Converter from SplendApp. Maintaining the image quality, try it NOW!";
        }else{
            this.title = 'محوّل الصور | تحويل الصورة إلى أي صيفة مجاناً | سبلينداب';
            this.description = 'وفِّر المال والوقت والجهد في صياغة صور فريدة وفعالة مع محول الصور المجاني من سبلينداب. الحفاظ على جودة الصورة، جربه الآن!';
        }
    document.title = this.title;
    document.querySelector('meta[name="description"]').setAttribute('content', this.description);
  },
  watch: {
    '$store.state.currentLanguage': function(newLanguage) {
      this.currentLanguage = newLanguage
      if(this.currentLanguage == 'en'){
            this.$router.push({name: 'format-converter-en'});
            this.title = 'Free Image Converter | Image Format Converter |SplendApp';
            this.description = "Save money, time and effort in crafting unique and effective images with Free Image Converter from SplendApp. Maintaining the image quality, try it NOW!";
        }else{
            this.$router.push({name: 'format-converter-ar'});
            this.title = 'محوّل الصور | تحويل الصورة إلى أي صيفة مجاناً | سبلينداب';
            this.description = 'وفِّر المال والوقت والجهد في صياغة صور فريدة وفعالة مع محول الصور المجاني من سبلينداب. الحفاظ على جودة الصورة، جربه الآن!';
        }
      document.title = this.title;
        document.querySelector('meta[name="description"]').setAttribute('content', this.description);
    },
  },
}
</script>
<style scoped lang='scss'>
    p{
      font-size: 14px;
      color: #716c80;
    }
 .section-title{
    h2{
      line-height: 1.4;
      margin-bottom: 0;
      font-size: 36px;
      font-weight: 700;
      color: #262A37;
    }
    p{
      font-size: 21px;
      color: #716c80;
      margin-bottom: 15px;
    }
  }
  .image{
    img{
      width: 100%;
    }
  }
  section {
    .row{
      margin-top: 100px;
    }
  }
  .col-lg-4:has(.features-item), .col-sm-6:has(.features-item), .col-md-6:has(.features-item){
    margin-bottom: 25px;
  }
  .features-item{
    padding: 30px 19px;
    border: 1px solid #eee;
    transition: all 0.3s ease-in-out;
    margin-bottom: 0px;
    height: 100%;
    text-align: center;
    h3 {
        font-size: 20px;
        margin-bottom: 12px;
        color: #262a37;
        font-weight: 700;
    }
    p{
      margin-bottom: 0;
    }
  }
  .features-item:hover{
    box-shadow: 0 25px 75px rgba(25,34,64,.15);
  }
.intro-video-content .sub-title {
    display: block;
    margin-bottom: 10px;
    background: linear-gradient(44.44deg, #0053fe 7.79%, #3a7afe 94.18%);;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: .1em;
    font-weight: 600;
}
.intro-video-content h2{
    line-height: 1.4;
    color: #24365c;
    font-size: 48px;
    margin-bottom: 15px;
    font-weight: 700;
}
.intro-video-content li {
    color: #5f5f5f;
    font-size: 18px;
}
.intro-video-content .default-btn {
    margin-top: 10px;
    color: white;
    background: linear-gradient(44.44deg,#3a7afe .79%,#0053fe 94.18%);
    padding: 12px 35px;
    font-weight: 600;
    font-size: 14px;
    border-radius: 30px;
    text-decoration: none;
    color: white;
    display: inline-block;
    margin-bottom: 50px;
}
iframe{
  height: 400px;
  width: 100%;
  border-radius: 10px;
}
.single-features-item {
    background-color: white;
    transition: .5s;
    padding: 40px 30px 35px 110px;
    margin-bottom: 25px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
}
.single-features-item h3 {
    font-size: 20px;
    font-weight: 700;
}
.single-features-item:hover {
    box-shadow: 0 50px 100px -20px rgba(50,50,93,.1), 0 30px 60px -30px rgba(107,124,147,.15);
}
.single-features-item:hover::before {
    opacity: 1;
    visibility: visible;
}
.single-features-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: linear-gradient(44.44deg,#3a7afe .79%,#0053fe 94.18%);
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: .5s;
}
.single-features-item:hover a, .single-features-item:hover h3, .single-features-item:hover p {
    color: white;
}
.image{
    max-width: 100%;
}
@media (max-width: 769px) {
    .single-features-item{
        padding-left: 30px;
    }
    
}
@media (min-width: 1400px){
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1140px;
    }
}
</style>
