<template>
<div class="container">
    <nav v-if="currentLanguage == 'en'" class="navbar navbar-expand-lg">
        <div class="logo">
            <a href="https://splendapp.com/">
                <img src="@/assets/Splendapp-web.svg" alt="splendapp logo" />
            </a>
        </div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon">
                    <i class="fas fa-bars"></i>
                </span>
            </button>
        <div class="collapse navbar-collapse text-center tabs justify-content-center" id="navbarSupportedContent">
            <ul class="navbar-nav">
                <li class="nav-item en"><router-link :to="{name : 'image-editor-en'}">Image Editor</router-link></li>
                <li class="nav-item en"><a href="/Free_Wireframe_Design_Tool_from_SplendApp/">Wireframe Tool</a></li>
                <!--<li class="nav-item en list" >
                    Generators <svg style="width: 12px;height: 12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>
                    <ul>
                        <li class="list-item"><a href="">App Downloads OneLink</a></li>
                        <li class="list-item"><a href="">QR Code Generator</a></li>
                        <li class="list-item"><a href="">Barcode Generator</a></li>
                    </ul>
                </li>-->
                <li class="nav-item en list" >
                    More Tools <svg style="width: 12px;height: 12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>
                    <ul>
                        <li class="list-item"><router-link :to="{name : 'background-removal-en'}"> Background Removal</router-link></li>
                        <li class="list-item"><router-link :to="{name : 'image-compress-en'}"> Compressor</router-link></li>
                    <!--<li class="list-item"><a href="">Image Enlarger</a></li>-->
                        <li class="list-item"><router-link :to="{name : 'color-picker-en'}"> Color Picker</router-link></li>
                        <li class="list-item"><router-link :to="{name : 'format-converter-en'}">Image PNG Converter</router-link></li>
                        <li class="list-item"><router-link :to="{name : 'gif-converter-en'}">GIF Maker</router-link></li>

                    </ul>
                </li>
                <li>
                    <div class="language">
                        <div class="" v-if="currentLanguage == 'en'" @click="changeLanguage('ar')">
                            <div class="text">العربية</div>
                            <img src="@/assets/sau.png" alt="saudi flag" />
                        </div>
                        <div class="" v-if="currentLanguage == 'ar'" @click="changeLanguage('en')">
                            <div class="text">English</div>
                            <img src="@/assets/en.png" alt="saudi flag" />
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        
    </nav>
    <nav v-else-if="currentLanguage == 'ar'" class="navbar navbar-expand-lg" style="direction: rtl">
        <div class="logo">
            <a href="https://splendapp.com/">
                <img src="@/assets/Splendapp-web.svg" alt="splendapp logo" />
            </a>
        </div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon">
                    <i class="fas fa-bars"></i>
                </span>
            </button>
        <div class="collapse navbar-collapse text-center tabs justify-content-center" id="navbarSupportedContent">
            <ul class="navbar-nav">
                <li class="nav-item ar"><router-link :to="{name : 'image-editor-ar'}">محرر الصور</router-link></li>
                <li class="nav-item ar"><a href="/أداة_الرسم_Wireframing_المجانية_من_سبلينداب/">أداة الرسم Wireframing</a></li>
                <!--<li class="nav-item ar list" >
                    المولدات <svg style="width: 12px;height: 12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>
                    <ul>
                        <li class="list-item"><a href="">الرابط الواحد لتحميل التطبيق</a></li>
                        <li class="list-item"><a href="">مولد الرمز QR</a></li>
                        <li class="list-item"><a href="">مولد الباركود</a></li>
                    </ul>
                </li>-->
                <li class="nav-item ar list" >
                    المزيد من الأدوات <svg style="width: 12px;height: 12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>
                    <ul>
                        
                        <li class="list-item"><router-link :to="{name : 'background-removal-ar'}">إزالة خلفية الصورة</router-link></li>
                        <li class="list-item"><router-link :to="{name : 'image-compress-ar'}">ضاغط حجم الصور</router-link></li>
                        <!--<li class="list-item"><a href="">مكبر حجم الصور</a></li>-->
                        <li class="list-item"><router-link :to="{name : 'color-picker-ar'}">محدد لون الصور</router-link></li>
                        <li class="list-item"><router-link :to="{name : 'format-converter-ar'}">محول الصور إلى PNG</router-link></li>
                        <li class="list-item"><router-link :to="{name : 'gif-converter-ar'}">صانع الـ GIF</router-link></li>
                    </ul>
                </li>
                <li class="ar">
                    <div class="language">
                        <div class="" v-if="currentLanguage == 'en'" @click="changeLanguage('ar')">
                            <div class="text">العربية</div>
                            <img src="@/assets/sau.png" alt="saudi flag" />
                        </div>
                        <div class="" v-if="currentLanguage == 'ar'" @click="changeLanguage('en')">
                            <div class="text">English</div>
                            <img src="@/assets/en.png" alt="saudi flag" />
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        
    </nav>
</div>
</template>

<script>
export default {
    data() {
        return {
            currentLanguage: this.$store.state.currentLanguage
        }
    },
    watch: {
        '$store.state.currentLanguage': function(newLanguage) {
            this.currentLanguage = newLanguage
        },
    },
    methods: {
        changeLanguage(language) {
            this.$store.commit('setCurrentLanguage', language)
        }
    }
}

</script>

<style lang="scss" scoped>
@import url('');

.nav-item.list ul{
    display: none;
    z-index: 10000;
    position: absolute;
    left: 50%;
    background-color: white;
    padding: 1rem;
    box-shadow: 0 7px 13px 0 rgba(0,0,0,.1);
    border-radius: 10px;
    width: 0;
    height: 0;
    transition: all 0.3s ease-in-out;
    
    li{
        margin: 0;
        padding: 0;
        transition: all 0.3s ease-in-out;
        &:hover{
            transform: scale(1.1);
        }
        a{
            color: var(--main-color);
            text-decoration: none;
            display: block;
            padding: 0.5rem 0;
        }
    }
}
.nav-item.list.ar{
    position: relative;
}
.nav-item.list:hover ul{
    transition: all 0.3s ease-in-out;
    display: block;
    width: 30%;
    height: auto;
}
.nav-item.list.ar:hover ul{
        left: -50%;
        width: 300px;
}
nav {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2rem;
    .navbar-toggler:focus {
        box-shadow: none;
    }
    i{
            font-size: 30px;
        }
    .logo {
        img {
            width: 140px;
        }
    }
    ul {
        list-style: none;
        display: flex;
        margin: 0;
        li {
            margin: 0 1rem;
            a {
                text-decoration: none;
                color: var(--main-color);
            }
        }
        
    }
    .language {
        div{
            display: flex;
        align-items: center;
        color: var(--main-color);
        cursor: pointer;
        }
        
        img {
            width: 16px;
            margin-left: 0.5rem;
        }
    }
}
@media (min-width: 991px) {
    ul{
        width: 100%;
        justify-content: center;
        padding-right: 0;
        li:nth-of-type(1){
            margin-left: auto;
        }
        li:last-of-type{
            margin-left: auto;
        }
        li.ar:nth-of-type(1) {
            margin-right: auto;
            margin-left: 0;
        }
        li.ar:last-of-type{
            margin-right: auto !important;
            margin-left: 0;
            img {
                margin-left: 0;
                margin-right: 0.5rem;
            }
        }

    }
}
@media (max-width: 768px) {
    ul{
        box-shadow: 0 7px 13px 0 rgba(0,0,0,.1);
        margin-top:20px !important;
        li {
            padding: 1em 5%;
            border-top: 1px solid #dbeefd;
            margin: 0 !important;
            &.en{
                text-align: left;
            }
            &.ar{
                text-align: right;
            }
        }
        .language > div{
            justify-content: center;
        }
    }
    .nav-item.list ul{
        display: block;
        width: 100%;
        height: auto;
        position: relative;
        left: unset;
        box-shadow: none;
        padding: 0 0 0 30px;
        li{
            border: none;
        }
    }
    .nav-item.list.ar ul{
        padding: 0 30px 0 0;
    }
    ul:has(li.ar){
        padding-right: 0;
    }
}
</style>

