<template>
<div>
  <NavBar/>
  <router-view/>
  <Footer/>
</div>
</template>

<script>
import 'bootstrap';
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: {
    NavBar,
    Footer
  }
}
</script>
<style lang="scss">
:root{
  --main-color: #24365c;
}
#app {
  font-family: 'Cairo', sans-serif;
}
body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Cairo', sans-serif;
}
@import url('./assets/styles/bootstrap.min.css');
@import url('./assets/styles/all.min.css');
</style>
