<template>
  <div>
    <tui-image-editor ref="imageEditor" :include-ui="includeUi" :options="options"></tui-image-editor>
  </div>
</template>

<script>
import 'tui-color-picker/dist/tui-color-picker.css';
import 'tui-image-editor/dist/tui-image-editor.css';
import { ImageEditor } from '@toast-ui/vue-image-editor';
import defaultImage from '../assets/defualt.jpg';


export default {
 components: {
    'tui-image-editor': ImageEditor,
  },
  data() {
    const icona = require('tui-image-editor/dist/svg/icon-a.svg')
    const iconb = require('tui-image-editor/dist/svg/icon-b.svg')
    const iconc = require('tui-image-editor/dist/svg/icon-c.svg')
    const icond = require('tui-image-editor/dist/svg/icon-d.svg')
    var whiteTheme = {
      'menu.normalIcon.path': icond,
      'menu.activeIcon.path': iconb,
      'menu.disabledIcon.path': icona,
      'menu.hoverIcon.path': iconc,
      'submenu.normalIcon.path': icond,
      'submenu.activeIcon.path': iconb
    }
    return {
      path: 'img/sampleImage.jpg',
      includeUi:true,
      options: {
        // for tui-image-editor component's "options" prop
        cssMaxWidth: 700,
        cssMaxHeight: 500,
        includeUI: {
          loadImage: {
            path: defaultImage,
            name: 'SampleImage',
          },
         theme: {
          'common.backgroundColor': '#ffffff',
          'common.backgroundImage': 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAABemlDQ1BJQ0MgUHJvZmlsZQAAKM9jYGAqSSwoyGFhYGDIzSspCnJ3UoiIjFJgv8PAzcDDIMRgxSCemFxc4BgQ4MOAE3y7xsAIoi/rgsxK8/x506a1fP4WNq+ZclYlOgz4AXdKanEyAwMjB5CdnFKcnAtk5wDZOskFRSVA9gwgW7e8pADEPgFkixQBHQhk3wGx0yHsDyB2EpjNxAJWExLkDGRLANkCSRC2BoidDmFbgNjJGYkpQLYHyC6IG8CA08NFwdzAUteRgcogN6cUZgcotHhS80KDQe4AYhkGDwYXBgUGcwYDBksGXQbHktSKEpBC5/yCyqLM9IwSBUdgyKYqOOfnFpSWpBbpKHjmJevpKBgZGBqA1IHiDGL05yCw6Yxi5xFi+QsZGCyVGRiYexBiSdMYGLbvYWCQOIUQU5nHwMBvzcCw7VxBYlEi3OGM31gI8YvTjI0gbB4nBgbWe///f1ZjYGCfxMDwd+L//78X/f//dzHQfmCcHcgBACR3aeD10IV6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABnGlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNS40LjAiPgogICA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPgogICAgICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIgogICAgICAgICAgICB4bWxuczpleGlmPSJodHRwOi8vbnMuYWRvYmUuY29tL2V4aWYvMS4wLyI+CiAgICAgICAgIDxleGlmOlBpeGVsWERpbWVuc2lvbj4xMTA8L2V4aWY6UGl4ZWxYRGltZW5zaW9uPgogICAgICAgICA8ZXhpZjpQaXhlbFlEaW1lbnNpb24+OTk8L2V4aWY6UGl4ZWxZRGltZW5zaW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KydpWSQAAACVJREFUKFNj/Pnz538GIgFYMRsbG5SLHzBBaaLAqGJkQCvFDAwAlKIH+ycXcc8AAAAASUVORK5CYII=',
          'submenu.backgroundColor': 'transparent',
          'header.backgroundColor': 'white',
          'submenu.partition.color': 'black',
           'range.subbar.color': 'black',
          'submenu.normalLabel.color': '#000',
          'submenu.normalLabel.fontWeight': 'bold',
          'submenu.activeLabel.color': '#000',
          'submenu.normalIcon.color': 'black',
          'submenu.activeIcon.color': 'black',

          'submenu.activeLabel.fontWeight': 'bold',
          'menu.backgroundColor': '#ffffff',
          'menu.normalIcon.color': 'white',
          'menu.activeIcon.color': '#555555',
          'checkbox.border': '1px solid #ccc',
          'checkbox.backgroundColor': '#fff',
          'range.pointer.color': 'black',
    'range.bar.color': '#666',
    'range.subbar.color': '#d1d1d1',

    'range.disabledPointer.color': '#ddd',
    'range.disabledBar.color': '#ddd',
    'range.disabledSubbar.color': '#ddd',

    'range.value.color': '#fff',
    'range.value.fontWeight': 'lighter',
    'range.value.fontSize': '11px',
    'range.value.border': '1px solid #353535',
    'range.value.backgroundColor': '#151515',
    'range.title.color': '#fff',
    'range.title.fontWeight': 'lighter',
         },
          initMenu: 'filter',
          menuBarPosition: 'left',
       },
      },
    };
  },
  methods: {
    saveImage() {
      const editorInstance = this.$refs.imageEditor.getInstance();
      const dataURL = editorInstance.toDataURL();
      // Do something with the dataURL, like sending it to a server or displaying it on the page.
      //download the image

      const link = document.createElement('a');
      link.href = dataURL
      link.download = 'image.png';
    },
    dataUtlToBlob(base64) {
      const base64WithoutPrefix = base64.replace(/^data:image\/[a-z]+;base64,/, '');
      const binaryString = window.atob(base64WithoutPrefix);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const type = 'image/png';
      return new Blob([bytes], { type });
    },
    loadDefaultImage() {
      // Load your default image here
      
      import('@/assets/defualt.jpg').then((image) => {
        this.$refs.imageEditor.invoke('loadImageFromURL',image.default,'My sample image');
      });
    },
  },
  mounted() {
    // Access the image editor component using the ref
    const imageEditor = this.$refs.imageEditor;
    //this.loadDefaultImage();
     import('@/assets/defualt.jpg').then((image) => {
        this.path = image.default;
      });
    // Update the logo image source
    const logoImage = imageEditor.$el.querySelector('.tui-image-editor-header-logo > img');
    import('@/assets/logo2.png').then((logo) => {
      logoImage.src = logo.default;
    });
    //download the image
    const downloadButton = imageEditor.$el.querySelector('.tui-image-editor-header > div.tui-image-editor-header-buttons >  .tui-image-editor-download-btn');
    downloadButton.addEventListener('click', () => {
      const imagelink = imageEditor.invoke('toDataURL', 'image/png');
      const theLink = URL.createObjectURL(this.dataUtlToBlob(imagelink)) ;
      const link = document.createElement('a');
      link.href = theLink;
      console.log(link);
      link.download = 'image.png';
      link.click();
    });
    
  },
};

</script>
<style >
.tui-image-editor-container{
    height: 100vh !important;
}
.tui-image-editor-container .tui-image-editor-download-btn{
  background-color: #3a7afe !important;
  border: none !important;
}
.tui-image-editor-container .tui-image-editor-controls{
  background-color: #3a7afe !important;
  border: none !important;
}
.tui-image-editor-submenu label{
  color: black !important;  
}
.tui-image-editor-container .tui-image-editor-help-menu.right .tui-image-editor-item > svg >  use.normal.use-default, .tui-image-editor-menu use.normal.use-default, .tui-image-editor-help-menu use.normal.use-default{
  fill: black;
  stroke: black ;
}
.tui-image-editor-container .tui-image-editor-controls-logo > img, .tui-image-editor-container .tui-image-editor-header-logo > img{
  width: auto !important;
}
</style>