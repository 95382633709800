import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        currentLanguage: 'en',
    },
    mutations: {
        setCurrentLanguage(state, language) {
            state.currentLanguage = language;
        }
    },
    getters: {
        currentLanguage: state => state.currentLanguage,
    },
    actions: {
        
    },
    modules: {
    }
})
