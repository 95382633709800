<template>
  <div class="image-resizer">
    <div class="resizer-image">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"/></svg>
      <span>Drop Image Here</span>
    </div>
    <label for="image-input">choose file</label>
    <input style="display: none" type="file" @change="handleFileChange" id="image-input" accept="image/*">
  </div>
</template>

<script>
import Pica from 'pica';

export default {
  data() {
    return {
      resizedImage: null,
    };
  },
  methods: {
    async handleFileChange(event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = async (e) => {
        const img = new Image();
        img.src = e.target.result;

        img.onload = async () => {
          const pica = new Pica();
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          const targetSizeInKB = 20;
          const targetSizeInBytes = targetSizeInKB * 1024;

          const desiredWidth = img.width;
          const desiredHeight = img.height;

          canvas.width = desiredWidth;
          canvas.height = desiredHeight;

          ctx.drawImage(img, 0, 0, desiredWidth, desiredHeight);

          const resizedCanvas = document.createElement('canvas');
          resizedCanvas.width = desiredWidth;
          resizedCanvas.height = desiredHeight;

          await pica.resize(canvas, resizedCanvas);

          const resizedImageData = await pica.toBlob(resizedCanvas, 'image/jpeg', 0.1); // Adjust the quality value as needed
          const resizedFile = new File([resizedImageData], file.name, { type: 'image/jpeg' });
          const resizedImageURL = URL.createObjectURL(resizedFile);
          this.resizedImage = resizedImageURL;
           this.downloadResizedImage();
           
        };
      };

      reader.readAsDataURL(file);
    },
    downloadResizedImage() {
      const link = document.createElement('a');
      link.href = this.resizedImage;
      link.download = 'resized_image.jpg';
      link.click();
      this.resizedImage = null;
    },
  },
};
</script>
<style scoped>
.image-resizer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4796ec;
  height: 280px;
  flex-direction: column;
}
.resizer-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2rem;
}
.resizer-image svg {
  width: 50px;
  height: 50px;
  fill: white;
}
label{
  cursor: pointer;
  padding: .5rem 1rem;
  background-color: #fff;
  border-radius: 10px;
  color: black;
  font-weight: bold;
  margin-top: 10px;
}
</style>